<template>
  <section class="file-upload-wrapper">
    <el-upload
      class="file-uploader"
      ref="uploaderRef"
      :action="onUploadUrl"
      :headers="headers"
      :show-file-list="false"
      :on-change="handleChange"
      :before-upload="beforeUpload"
      :on-error="onUploadError"
      :file-list="fileList"
      :auto-upload="false"
      drag
      multiple
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <!-- <div class="el-upload__tip" slot="tip">
        只能上传jpg/png文件，且不超过500kb
      </div> -->
    </el-upload>
    <section class="file-list-wrapper">
      <ul class="file-list">
        <li
          class="file-list-item"
          v-for="(item, index) in fileList"
          :key="item.uid"
        >
          <div>
            <i class="el-icon-close text-nav" @click="onRemoveFile(index)"></i>
            <el-input
              size="small"
              placeholder="请输入文件名称"
              prefix-icon="el-icon-document"
              v-model="item.name"
              :disabled="isUploading"
            >
              <template slot="append">
                <i
                  class="el-icon-circle-check"
                  v-if="item.percentage === 100"
                ></i>
              </template>
            </el-input>
          </div>
          <div class="file-desc">
            <span>{{ getFileSizeName(item.raw.size) }}</span>
            <span>{{ getFileTypeName(item.raw.type) }}</span>
          </div>
          <el-progress
            class="upload-progress"
            :percentage="item.percentage"
          ></el-progress>
        </li>
      </ul>
    </section>
    <div class="upload-control">
      <el-button
        style="margin-left: 10px"
        size="small"
        type="success"
        :disabled="fileList.length <= 0"
        :loading="isUploading"
        @click="submitUpload"
        >{{ isUploading ? "上传中..." : "上传到服务器" }}</el-button
      >
      <el-button
        style="margin-left: 10px"
        size="small"
        :disabled="isUploading"
        @click="onClose"
        >关 闭</el-button
      >
    </div>
  </section>
</template>

<script>
import API from "@/request/api.config.js";
import { getToken } from "@/utils/auth";
import { getFileSizeName, getFileTypeName } from "@/utils/common";
import { ShowApiError } from "@/request/error";
import { PostFiles } from "../api";
export default {
  data() {
    return {
      isUploading: false,
      fileList: [],
      fileUploader: null,
      successIds: [],
      statusCheckTimer: null,
    };
  },
  computed: {
    onUploadUrl() {
      return API.BASE_V1_API + "/oss/upload";
    },
    headers() {
      return {
        Authorization: getToken(),
      };
    },
  },
  mounted() {
    this.fileUploader = this.$refs.uploaderRef;
  },
  methods: {
    getFileSizeName,
    getFileTypeName,
    // 同步el-upload数据
    syncElUpload(val) {
      const filesList = val || this.filesList;
      this.fileUploader.uploadFiles = filesList.map((item) => {
        return item;
      });
    },
    beforeUpload(file) {
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error("上传的文件大小不能超过 4MB!");
      } else {
        this.isUploading = true;
      }
      return isLt4M;
    },
    // onSuccessUpload(res, file) {
    //   if (
    //     res.fileName &&
    //     file.percentage === 100 &&
    //     file.status === "success"
    //   ) {
    //     PostFiles({
    //       contentLength: res.contentLength,
    //       contentType: res.contentType,
    //       fileName: res.fileName,
    //       name: res.name,
    //     })
    //       .then(() => {})
    //       .catch((err) => {
    //         ShowApiError("文件记录失败", err);
    //       });
    //   } else {
    //     this.$message.error(res.msg);
    //   }
    //   this.isUploading = false;
    // },
    onUploadError() {
      this.isUploading = false;
    },
    handleChange(file) {
      const uploaderFileList = this.fileUploader.uploadFiles;
      if (uploaderFileList.length <= 0) {
        this.fileList = [];
      } else {
        this.fileList = uploaderFileList.map((item) => {
          return {
            ...item,
            hasRecored: false,
          };
        });
      }
      if (file.percentage === 100 && file.status === "success") {
        PostFiles({
          contentLength: file.raw.size,
          contentType: file.raw.type,
          fileName: file.response.fileName,
          name: file.raw.name,
        })
          .then(() => {
            if (!this.successIds.includes(file.uid)) {
              this.successIds.push(file.uid);
            }
            this.fileList = this.fileList.map((item) => {
              if (this.successIds.includes(item.uid)) {
                item.hasRecored = true;
              }
              return item;
            });
          })
          .catch((err) => {
            this.fileList = this.fileList.map((item) => {
              if (!this.successIds.includes(item.uid)) {
                item.hasRecored = false;
              }
              return item;
            });
            ShowApiError(err, "文件记录失败");
          })
          .finally(() => {
            this.checkAllFileUploadStatus();
          });
      }
    },
    onRemoveFile(index) {
      this.fileList.splice(index, 1);
      this.syncElUpload(this.fileList);
    },
    checkAllFileUploadStatus() {
      if (this.fileList.length > 0) {
        clearInterval(this.statusCheckTimer);
        this.statusCheckTimer = setInterval(() => {
          let status = this.fileList.every((item) => {
            if (item.hasRecored === true) {
              return true;
            }
          });
          clearInterval(this.statusCheckTimer);
          if (status) {
            this.fileList = [];
            this.successIds = [];
            this.fileUploader.uploadFiles = [];
            this.isUploading = false;
            this.$message.success("所有文件上传成功");
          }
        }, 1000);
      } else {
        clearInterval(this.statusCheckTimer);
      }
    },
    submitUpload() {
      if(this.fileList.length <= 0){
        this.$message.error("请先选择文件");
        return false
      }
      this.fileUploader.submit();
    },
    onClose() {
      if (this.fileList.length > 0) {
        let isUploadSuccess = this.fileList.uploadFiles.every(
          (item) => item.percentage === 100 && item.hasRecored === true
        );
        if (isUploadSuccess) {
          this.fileList = [];
          this.successIds = [];
          this.fileUploader.uploadFiles = [];
        } else {
          this.$confirm(
            "你有文件尚未上传完成，关闭将清空上传列表，是否确定关闭？",
            "提示",
            {
              confirmButtonText: "确定关闭",
              cancelButtonText: "取消关闭",
              type: "warning",
            }
          )
            .then(() => {
              this.fileList = [];
              this.successIds = [];
              this.fileUploader.uploadFiles = [];
              this.$emit("close");
            })
            .catch(() => {});
        }
      } else {
        this.$emit("close");
        this.fileList = [];
        this.successIds = [];
        this.fileUploader.uploadFiles = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload-wrapper {
  width: 100%;
  box-sizing: border-box;
  .file-uploader {
    text-align: center;
  }
  ::v-deep {
    .el-upload {
      width: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
    .el-upload-dragger {
      width: 100%;
      border-radius: 0;
    }
  }
}
.upload-control {
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  text-align: right;
  border-top: 1px solid #f1f1f1;
  background-color: #fff;
  z-index: 10;
}
.file-list-wrapper {
  box-sizing: border-box;
  padding: 15px;
  margin: 10px;
  list-style-type: none;
  .file-list {
    width: 100%;
    overflow: hidden;
    .file-list-item {
      box-sizing: border-box;
      padding: 15px 20px;
      padding-bottom: 20px;
      border-radius: 4px;
      border: 1px solid #f1f1f1;
      position: relative;
      margin-bottom: 15px;
      position: relative;
      .el-icon-document {
        font-size: 14px;
        color: #333;
        padding-right: 10px;
      }
      .el-icon-close {
        display: inline-block;
        width: 18px;
        height: 18px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: rgb(247, 10, 10);
        border-bottom-left-radius: 10px;
        z-index: 1;
        &:hover {
          background-color: #d40303;
        }
      }
      .upload-progress {
        box-sizing: border-box;
        position: absolute;
        bottom: 2px;
        left: 20px;
        right: 0;
      }
      .file-desc {
        font-size: 13px;
        color: #989898;
        margin-top: 10px;
        & span {
          padding-right: 10px;
        }
      }
    }
  }
}
</style>